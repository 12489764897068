exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-exito-js": () => import("./../../../src/pages/contacto/exito.js" /* webpackChunkName: "component---src-pages-contacto-exito-js" */),
  "component---src-pages-contacto-index-js": () => import("./../../../src/pages/contacto/index.js" /* webpackChunkName: "component---src-pages-contacto-index-js" */),
  "component---src-pages-icons-js": () => import("./../../../src/pages/icons.js" /* webpackChunkName: "component---src-pages-icons-js" */),
  "component---src-templates-identity-email-js": () => import("./../../../src/templates/identity-email.js" /* webpackChunkName: "component---src-templates-identity-email-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-personal-js": () => import("./../../../src/templates/personal.js" /* webpackChunkName: "component---src-templates-personal-js" */),
  "component---src-templates-schema-js": () => import("./../../../src/templates/schema.js" /* webpackChunkName: "component---src-templates-schema-js" */),
  "component---src-templates-servicio-individual-js": () => import("./../../../src/templates/servicio-individual.js" /* webpackChunkName: "component---src-templates-servicio-individual-js" */),
  "component---src-templates-servicios-js": () => import("./../../../src/templates/servicios.js" /* webpackChunkName: "component---src-templates-servicios-js" */),
  "component---src-templates-sobre-nosotros-js": () => import("./../../../src/templates/sobre-nosotros.js" /* webpackChunkName: "component---src-templates-sobre-nosotros-js" */),
  "component---src-templates-trabajos-realizados-js": () => import("./../../../src/templates/trabajos-realizados.js" /* webpackChunkName: "component---src-templates-trabajos-realizados-js" */)
}

